.site-status-comp {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding: 28px 18px 0px 18px;
  background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    url("../../assets/images/o1.jpg");
  background-size: cover;
  background-repeat: repeat;

  @media all and (max-width: 760px) {
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
      url("../../assets/images/form_bg.jpg");
    background-size: cover;
    background-repeat: repeat;
  }

  .bg-image {
    width: 100vw;
    height: 168vh;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -99;

    img {
      width: 100vw;
      height: 168vh;
      object-fit: cover;
      // filter: brightness(98%);
    }

    @media all and (max-width: 760px) {
      -webkit-filter: brightness(90%); /* Safari 6.0 - 9.0 */
      filter: brightness(90%);
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    .title {
      width: 100%;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      color: #fff;
      font-size: 3vw;
      letter-spacing: 2.8px;
    }

    .sub-title {
      width: 100%;
      font-family: "Noto Sans TC", sans-serif;
      font-weight: 500;
      font-size: 2.8vw;
      color: #fafafa;
      letter-spacing: 15px;
    }

    @media all and (max-width: 760px) {
      // border: 2px solid red;
      .title {
        font-size: 10vw;
      }
      .sub-title {
        font-size: 3.8vw;
      }
    }
  }

  .form-area {
    @mixin placeholder-color($color) {
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: $color;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: $color;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: $color;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: $color;
      }
    }

    .input-area {
      // padding: 8px;
      // border: 2px solid red;
      display: flex;
      margin-top: 28px;
      width: 100%;

      .input-item {
        // border: 2px solid red;
        width: 25%;
        padding: 8px;
      }

      @media all and (max-width: 760px) {
        // border: 2px solid red;
        display: inline-flex;
        flex-wrap: wrap;
        flex-direction: column;

        .input-item {
          // border: 2px solid red;
          width: 100%;
          padding: 0px;
          margin-bottom: -5px;
        }
      }

      .label {
        width: 100%;
        // padding: 4px 8px;
        color: #fafafa;
        font-size: 15px;
        display: inline-flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        font-family: "Noto Sans TC", sans-serif;
        font-weight: 500;
        letter-spacing: 1px;

        .btn-area {
          display: inline-flex;
          flex-wrap: nowrap;

          justify-content: space-between;
          // padding: 0px 8px;
          p {
            width: 60px;
            color: #1890ff;
            text-align: center;
            // padding: 2px 8px;
            // border: 2px solid red;
            margin-right: 16px;
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
          }
        }
      }

      .input-field {
        width: 100%;
        padding: 8px 8px;
        font-size: 17px;
        background-color: #e8f0fe;
        color: #000;
        margin-top: 8px;
        margin-bottom: 28px;
        border-radius: 10px;

        .select-notice {
          color: #ff7875;
        }
        .input {
          width: 100%;
          border: none;
          @include placeholder-color(#ff7875);
          color: #000;
          background-color: #e8f0fe;
          line-height: 17px;
          font-size: 17px;
          padding-left: 8px;
        }

        .disable {
          color: #bfbfbf;
        }
      }

      .with-select-btn {
        display: inline-flex;
        flex-wrap: nowrap;

        .btn {
          width: 90px;
          height: 30px;
          margin-right: 8px;
          background-color: #1890ff;
          box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
          border-radius: 4px;
          display: inline-flex;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          color: #fff;
          padding: 8px;
          font-size: 13px;
          p {
            margin-left: 8px;
          }
        }
      }
    }

    .submit-btn-area {
      padding: 18px 0px;
      .am-button-primary {
        background-color: #4c8dff;
        border: #4c8dff;
      }
    }

    .photo-field {
      width: 100%;
      .btn-area {
        width: 100%;
        // border: 2px solid red;
        display: inline-flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        // .ant-btn {
        //   color: #1890ff;
        //   border: 1px solid #1890ff;
        //   border-radius: 10px;
        //   background-color: #fff;
        // }
      }

      .image-area {
        width: 100%;
        display: inline-flex;
        flex-wrap: wrap;
        padding: 10px 0px;

        .image-container {
          width: 80px;
          height: 104px;
          margin: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .ant-image-img {
          width: 80px;
          height: 80px;
          object-fit: cover;
        }
      }
    }
  }

  .timeline-container {
    // border: 2px solid red;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media all and (max-width: 760px) {
      // border: 2px solid red;
      display: inline-flex;
      flex-wrap: wrap;
      flex-direction: column;

      .timeline-area {
        width: 100%;
        // border: 2px solid red;
      }
    }
  }

  .timeline-area {
    // border: 2px solid red;
    background-color: #fafafa;
    width: calc(33% - 8px);
    border-radius: 15px;
    padding: 16px;
    // min-height: 63vh;
    .title {
      width: 100%;
      font-size: 20px;
      font-family: "Noto Sans TC", sans-serif;
      border-radius: 15px;
      color: #fff;
      padding: 8px 10px;
      letter-spacing: 2.8px;
    }

    .capacity-title {
      background-color: #13c2c2;
    }

    .docs-title {
      background-color: #eb2f96;
    }

    .engi-title {
      background-color: #1890ff;
    }

    .ant-divider {
      margin: 10px 0px 24px 0px;
    }

    .no-data-area {
      // border: 2px solid red;
      margin-top: -8px;
      .ant-result {
        //
        padding: 0px 0px;
      }
    }
  }

  .timeline-area {
    margin-bottom: 28px;
  }

  .ant-progress-text {
    color: #fff;
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
  }

  .item-progress {
    margin-left: 20px;
    .ant-progress-text {
      color: #000;
      font-size: 13px;
    }
  }
}

.emp-select-option,
.ant-select {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  .emp-select-option-name {
    margin-left: 10px;
  }
}

.last-capacity {
  // border: 2px solid red;
  margin-top: -10px;
  // margin-bottom: 8px;
}

.hide-in-mobile {
  @media all and (max-width: 760px) {
    display: none;
  }
}
