.business-card-comp {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  //   border: 2px solid red;
  display: flex;
  align-items: center;
  .btn-area {
    width: 100vw;
    padding: 10px;
    .line-brn {
      //   margin-top: 20vh;
      width: 100%;
      border-radius: 10px;
      height: 50px;
      background-color: #06c755;
      color: #fff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}
